import React, { Component } from 'react';
import menuDisenoCss from "./menuDiseno.module.css";
import { Link } from 'gatsby';

class MenuDesarrollo extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    render() { 
        return ( 
            <div className={menuDisenoCss.container}>
                <Link to="/Design/Marca" className={menuDisenoCss.link}>branding</Link>
                <Link to="/Design/Empaque" className={menuDisenoCss.link}>empaque</Link>
                <Link to="/Design/Fotografia" className={menuDisenoCss.link}>fotografía</Link>
                <Link to="/Design/Video" className={menuDisenoCss.link}>producción de video</Link>
            </div>
         );
    }
}
 
export default MenuDesarrollo;